import React, { useRef, useState } from "react";
import "./contact.css";
import email1 from "../../img/email1.min.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { themeContext } from "../../Context/Context";
import { useContext } from "react";
import { motion } from "framer-motion";
import Popup from "./Popup";

const Contact = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  const form = useRef();
  const [setDone] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const data = {
      name: formData.get("user_name"),
      email: formData.get("user_email"),
      subject: formData.get("subject"),
      message: formData.get("message"),
    };

    try {
      const response = await fetch(
        "https://mohamed-ali-portfolio-84ab870412ab.herokuapp.com/api/email/send",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      if (result.success) {
        // console.log('Email sent successfully:', result);
        setDone(true);
        setShowPopup(true);
        // Clear the form inputs
        e.target.reset();
        // Show success toast message
        toast.success(
          "Email Sent Successfully, Thanks for getting in touch, you should receive my reply shortly 💌"
        );
      } else {
        console.error("Error sending email:", result.error);
        // Show error toast message
        toast.error("Failed to send email. Please try again later.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      // Show error toast message
      toast.error("Failed to send email. Please try again later.");
    }
  };

  return (
    <div className="contact-form">
      <div className="c-left">
        <div className="contact-heading">
          <span
            style={{
              color: darkMode ? "white" : "",
            }}
          >
            Get in touch
          </span>
          <span className="contactMe">Contact me</span>{" "}
        </div>

        <motion.div
          initial={{ left: "-30%" }}
          whileInView={{ left: "20%" }}
          transition={{ duration: 2, ease: "easeInOut" }}
          className="floating-emailIcon"
        >
          <div className="floatingEmail">
            <img src={email1} alt="Email Icon" />
          </div>
        </motion.div>
      </div>
      <div className="c-right">
        {showPopup && (
          <Popup
            message="Thanks for reaching out 🫱🏻‍🫲🏼
             I will get back to you soon "
            onClose={() => setShowPopup(false)}
          />
        )}
        <form
          style={{
            backgroundColor: darkMode ? "#273740" : "rgb(199, 199, 199)",
          }}
          ref={form}
          onSubmit={sendEmail}
        >
          <input
            style={{
              backgroundColor: darkMode ? "rgb(199, 199, 199)" : "white",
            }}
            type="text"
            name="user_name"
            className="user"
            placeholder="Name"
          />
          <input
            style={{
              backgroundColor: darkMode ? "rgb(199, 199, 199)" : "white",
            }}
            type="email"
            name="user_email"
            className="user"
            placeholder="Email"
          />
          <input
            style={{
              backgroundColor: darkMode ? "rgb(199, 199, 199)" : "white ",
            }}
            type="text"
            name="subject"
            className="user"
            placeholder="Subject"
          />
          <textarea
            style={{
              backgroundColor: darkMode ? "rgb(199, 199, 199)" : "white ",
            }}
            name="message"
            className="user"
            placeholder="Inquiry ..."
          />
          <div className="submitBtn">
            <button type="submit" value="Send" className="button">
              Send{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1rem"
                height="1rem"
                viewBox="0 0 14 14"
              >
                <path
                  fill="white"
                  fillRule="evenodd"
                  d="M11.821.098a1.62 1.62 0 0 1 2.077 2.076l-3.574 10.712a1.62 1.62 0 0 1-1.168 1.069a1.6 1.6 0 0 1-1.52-.434l-1.918-1.909l-2.014 1.042a.5.5 0 0 1-.73-.457l.083-3.184l7.045-5.117a.625.625 0 1 0-.735-1.012L2.203 8.088l-1.73-1.73a1.6 1.6 0 0 1-.437-1.447a1.62 1.62 0 0 1 1.069-1.238h.003L11.82.097Z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          {/* <div
            className="blur2 blurP"
            style={{ background: darkMode ? "none" : "#E9E0F4" }}
          ></div> */}
        </form>
      </div>
    </div>
  );
};

export default Contact;
